<template>
  <div>
    <!-- <Navbar /> -->
    <div class="container-fluid">
      <div class="p-5">
        <h1>เกี่ยวกับเรา</h1>
        <div class="card">
          <div class="card-body">
            <ul><li>{{ this.email }}</li>
            <!--  <li>{{ this.link }}</li>
            <li>{{ this.detail }}</li>
            <li>{{ this.detail_dev }}</li>
            <li>{{ this.full_name }}</li>
            <li>{{ this.nick_name }}</li>
            <li>{{ this.address }}</li>
            <li>{{ this.degrees }}</li> 
            
            <li>{{ this.tel }}</li>-->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// import Navbar from "@/components/Navbar.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "About",
  components: {
    // Navbar,
    Footer,
  },
  data() {
    return {
      link: "aimaccount.com",
      detail: "Aim Account เป็นเครื่องมือบริการส่วนบุคคล",
      detail_dev: "ผู้พัฒนา WhiteAnt Kawaii Coding Team",
      full_name: "นายมรกต ทองพรหม",
      nick_name: "ต้น",
      address: "18/6 ถ.ราเมศวร ต.ในเมือง อ.เมืองพิษณุโลก จ.พิษณุโลก 65000",
      degrees: "ปริญญาโท สาขา เทคโนโลยีสารสนเทศ มหาวิทยาลัยนเรศวร",
      email: "admin",
      tel: "080-5151651",
    };
  },
};
</script>
